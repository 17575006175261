$(document).ready(function(){
	$(window).load(function(){
    vehicle_cat_ajax();
    servicesparent_page();
    careers_single_page();
    taxonomy_vehicle_page();
    gallery_page();
    services_page();
    vehicle_gallery_page();
    auto_loan_estimator();
    comparison_page();
    about_page();
    get_post_to_compare();
    // article_page();
		// Global Variables
  		var $pageHeight = $(window).height(),
  		$pageWidth = $(window).width(),
  		$navHeight = $('header.site-header').outerHeight(),
  		$quickLinks = $('nav.site-quicklinks').outerHeight(),
  		$footerHeight = $('footer.site-footer').outerHeight();
	});
});

function servicesparent_page(){
  var $windowHeight = $(window).outerHeight();
  var $headerHeight = $('header.site-header').outerHeight();
  var $footHeight = $('footer.site-footer').outerHeight();
  $('.services-parent').css({
    'height' : ($windowHeight - $headerHeight) + 8,
    'padding-top' : $headerHeight + 2
  });
  $('.auto-loan-est').css({
    'height' : ($windowHeight - $headerHeight) + 8,
    'padding-top' : $headerHeight + 2
  });
}

function careers_single_page(){
  var $positionTitle = $('.careers-single-page h1').data('positioncareers');
  $('#position-application').val($positionTitle);
  $('#position-application').attr("readonly", "readonly");
  $(document).on('click', '#upload-cv-application .sub-input', function(){
    $('input.browse-input').click();
  });
  $('.careers-single-page-application_form .wpcf7 .wpcf7-form-control-wrap.fileCvApplication input').change(function(e){
    var fileName = e.target.files[0].name;
    $('.careers-single-page-application_form .wpcf7 #upload-cv-application .sub-input .label').text(fileName);
  });
}

function vehicle_cat_ajax(){
  $(document).on('click', '.product-item-tag', function(){
    var $post_id = $(this).data('id');
    var $specsheet = $(this).data('specsheet');
    var $comparelink = $(this).data('comparelink');
    var $contentactive = $(this).data('contentactive');
    var $price = $(this).data('price');
    $(this).addClass('active-content').siblings().removeClass('active-content');
    $('.variation-slider-wrapper, .variation-navigation-wrapper').slick('unslick');
    $.ajax({
        url : $('.ajaxlocation').data('ajaxlocation'),
        type : 'POST',
        dataType: 'json',
        data : {
          action : 'change_model',
          post_id : $post_id,
          trigger : $contentactive
        },
        beforeSend: function(){
          $('.loader-overlay').css('display', 'block');
          setTimeout(function(){
            $('.loader-overlay').css('display', 'none');
          }, 500);
          // var content = $(this).data('contentactive');
          // $('.'+content).siblings().removeClass('active-content');
          // $('.'+content).addClass('active-content');
          // $(this).addClass('active-now').siblings().removeClass('active-now');
        },
        success: function(response) {
          $('#dl-spec').attr('href', $specsheet);
          $('#price').html($price);
          $('#add-compare').attr('href', $comparelink);
          $('.product-item-content').html(response.content);
          setTimeout(function(){
            taxonomy_vehicle_page();
            gallery_page();
          }, 300);
        }
      });
  });
}

function about_page(){
  $('.gallery_slider').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1
  });
  $(document).on('click', '#prev-wrap-arrow', function(){
    $('.gallery_slider button.slick-prev.slick-arrow').click();
  });
  $(document).on('click', '#next-wrap-arrow', function(){
    $('.gallery_slider button.slick-next.slick-arrow').click();
  });
}

function gallery_page(){
  $('.gallery-album-wrapper').each(function(){
    $(this).on('click', '.gallery-trig', function(){
      var $title = $(this).parent().find('.gallery-album a').first();
      $($title).click();
    });
  });
}

function taxonomy_vehicle_page(){
  $('.variation-slider-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: '.variation-navigation-wrapper'
  });
  $('.variation-navigation-wrapper').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.variation-slider-wrapper',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
    ]
  });
}

function services_page(){
  var $postwidth = $('.acc-post-item').width();
  $('.acc-post-item .acc-post-img').height($postwidth);
  $(document).on('click', '.parts-and-accessories-categories > li', function(){
    $(this).addClass('active-menu-item').siblings().removeClass('active-menu-item');
  });
  $('.parts-and-accessories-categories .trigger').each(function(){
    $(this).on('click', function(){
      var $parentpost_id = $(this).data('termid');
      var $parentpost_name = $(this).data('termname');
      $(this).removeClass('active-now');
      $(this).addClass('active-now');
      $.ajax({
        url : $('.ajaxlocation').data('ajaxlocation'),
        type : 'POST',
        dataType: 'json',
        data : {
          action : 'view_accessories',
          parentpost_id : $parentpost_id,
          parentpost_name : $parentpost_name,
        },
        beforeSend: function(){
          $('.loader-overlay').css('display', 'block');
          setTimeout(function(){
            $('.loader-overlay').css('display', 'none');
          }, 500);
        },
        success: function(response) {
          $('.execute-accessories-here').html(response.content);
          setTimeout(function(){
            $('.acc-post-item .acc-post-img').height($postwidth);
          },300);
        }
      });
    });
  });

  $('.parts-and-accessories-categories .triggers').each(function(){
    $(this).on('click', function(){
      var $servparentpost_id = $(this).data('termid');
      var $servparentpost_name = $(this).data('termname');
      $(this).removeClass('active-now');
      $(this).addClass('active-now');
      $.ajax({
        url : $('.ajaxlocation').data('ajaxlocation'),
        type : 'POST',
        dataType: 'json',
        data : {
          action : 'change_services',
          servparentpost_id : $servparentpost_id,
          servparentpost_name : $servparentpost_name,
        },
        beforeSend: function(){
          $('.loader-overlay').css('display', 'block');
          setTimeout(function(){
            $('.loader-overlay').css('display', 'none');
          }, 500);
        },
        success: function(response) {
          $('.execute-accessories-here-serv').html(response.content);
        }
      });
    });
  });
  $(document).on('click', '.services-inquiry', function(){
    var $servicetitle = $(this).parent().data('serv_title');
    // console.log($servicetitle);
    $('input#service-category').val($servicetitle);
    $('input#service-category').attr('readonly', 'true');
  });
}

function vehicle_gallery_page(){
  $(document).on('click', '#interior_gallery', function(){
    $('.loader-overlay').css('display', 'block');
    setTimeout(function(){
      $('.loader-overlay').css('display', 'none');
    }, 500);
    $(this).addClass('active-gallery').siblings().removeClass('active-gallery');
    $('#interior_gallery-container').addClass('active-gallery-container');
    $('#exterior_gallery-container').removeClass('active-gallery-container');
  });
  $(document).on('click', '#exterior_gallery', function(){
    $('.loader-overlay').css('display', 'block');
    setTimeout(function(){
      $('.loader-overlay').css('display', 'none');
    }, 500);
    $(this).addClass('active-gallery').siblings().removeClass('active-gallery');
    $('#interior_gallery-container').removeClass('active-gallery-container');
    $('#exterior_gallery-container').addClass('active-gallery-container');
  });
}

function auto_loan_estimator(){
  $(document).on('change', '#vehicle-category', function(){
    var $term_id_selected = $(this).val();
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'select_vehicle_category',
        term_id_selected : $term_id_selected
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      },
      success: function(response) {
        $('#vehicle-variant').html(response.content);
        $('#vehicle-variant').removeAttr('disabled');
        setTimeout(function(){
          vehicleVariant();
        }, 300);
      }
    });
    auto_loan_calculator();
  });
}

function vehicleVariant(){
  $(document).on('change', '#vehicle-variant', function(){
    var $variant_id_selected = $(this).val();
    var $category_id_selected = $('#vehicle-category').val();
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'select_show_car_to_estimate',
        variant_id_selected : $variant_id_selected,
        category_id_selected : $category_id_selected
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      },
      success: function(response) {
        $('#autoloan-result').html(response.content);
      }
    });
    auto_loan_calculator();
  });
  $(document).on('change', '#vehicle-downpayment', function(){
    auto_loan_calculator();
  });
  $(document).on('change', '#vehicle-paymentterms', function(){
    auto_loan_calculator();
  });
}

function auto_loan_calculator(){
  var $cat_select = $('#vehicle-category').val();
  var $var_select = $('#vehicle-variant').val();
  var $down_select = $('#vehicle-downpayment').val();
  var $pay_select = $('#vehicle-paymentterms').val();
  var $newprice = $('#vehicle-price-new').data('vehicleprice');
  var $percentage = 0;
  var $monthly_amor = 0;
  if ($cat_select != 'category-default' && $var_select != 'variant-default' && $down_select != 'down-default' && $pay_select != 'payment-default') {
    if ($down_select == 'downpayment-15') {
      $percentage = 0.15;
    }else if($down_select == 'downpayment-20'){
      $percentage = 0.20;
    }else if($down_select == 'downpayment-30'){
      $percentage = 0.30;
    }else if($down_select == 'downpayment-40'){
      $percentage = 0.40;
    }else if($down_select == 'downpayment-50'){
      $percentage = 0.50;
    }else{
      alert('SELECT DOWN PAYMENT PERCENTAGE');
    }
    $('#vehicle-downpayment-new').text('Php ' + ($newprice*$percentage));
    if ($pay_select == 'paymentterms-24') {
      $monthly_amor = [$newprice - ($newprice * $percentage)] * 1.3012 / 24;
    }else if($pay_select == 'paymentterms-36'){
      $monthly_amor = [$newprice - ($newprice * $percentage)] * 1.3650 / 36;
    }else if($pay_select == 'paymentterms-48'){
      $monthly_amor = [$newprice - ($newprice * $percentage)] * 1.4440 / 48;
    }else if($pay_select == 'paymentterms-60'){
      $monthly_amor = [$newprice - ($newprice * $percentage)] * 1.5275 / 60;
    }else{
      alert('SELECT TERMS');
    }
    $('#vehicle-monthly-new').text('Php ' + $monthly_amor.toFixed(2));

    
  }
}

function comparison_page(){
  $(document).on('click', '.vehicle-result-page-item', function(){
    $(this).addClass('active-table').siblings().removeClass('active-table');
  });
  $(document).on('change', '#compare-category-second', function(){
    $('#compare-model-second').removeAttr('disabled');
    var $parent_cat = $(this).val();
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'show_model_compare',
        parent_cat : $parent_cat
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      },
      success: function(response) {
        $('#compare-model-second').html(response.content);
      }
    });
  });
  $(document).on('change', '#compare-model-second', function(){
    $('#compare-variant-second').removeAttr('disabled');
    var $model_id = $(this).val();
    var $remove_model = $('#compare-variant').val();
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'show_variant_compare',
        model_id : $model_id,
        remove_model : $remove_model
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      },
      success: function(response) {
        $('#compare-variant-second').html(response.content);
      }
    });
  });
  $(document).on('change', '#compare-variant-second', function(){
    $('#third-car-compare').removeClass('disabled-item');
    $('#compare-category-third').removeAttr('disabled');
    var $variant_post = $(this).val();
    var $model_post = $('#compare-model-second').val();
    $('#second-car-compare .car-image').removeClass('no-image-wrapper');
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'show_car_to_compare',
        variant_post : $variant_post,
        model_post : $model_post
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      },
      success: function(response) {
        $('#second-car-compare .car-image').html(response.content);
        get_post_to_compare();
      }
    });
  });
  $(document).on('change', '#compare-category-third', function(){
    $('#compare-model-third').removeAttr('disabled');
    var $parent_cat = $(this).val();
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'show_model_compare_third',
        parent_cat : $parent_cat
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      },
      success: function(response) {
        $('#compare-model-third').html(response.content);
      }
    });
  });
  $(document).on('change', '#compare-model-third', function(){
    $('#compare-variant-third').removeAttr('disabled');
    var $model_id = $(this).val();
    var $remove_model = $('#compare-variant').val();
    var $remove_model_second = $('#compare-variant-second').val();
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'show_variant_compare_third',
        model_id : $model_id,
        remove_model : $remove_model,
        remove_model_second : $remove_model_second
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      },
      success: function(response) {
        $('#compare-variant-third').html(response.content);
      }
    });
  });
  $(document).on('change', '#compare-variant-third', function(){
    var $variant_post = $(this).val();
    var $model_post = $('#compare-model-third').val();
    $('#third-car-compare .car-image').removeClass('no-image-wrapper');
    $.ajax({
      url : $('.ajaxlocation').data('ajaxlocation'),
      type : 'POST',
      dataType: 'json',
      data : {
        action : 'show_car_to_compare_third',
        variant_post : $variant_post,
        model_post : $model_post
      },
      beforeSend: function(){
        $('.loader-overlay').css('display', 'block');
      },
      success: function(response) {
        $('#third-car-compare .car-image').html(response.content);
          get_post_to_compare();
        setTimeout(function(){
          $('.loader-overlay').css('display', 'none');
        }, 500);
      }
    });
  });
}

function get_post_to_compare(){
  var $first_post = $('#first-car-compare .car-image img').data('postid');
  var $second_post = $('#second-car-compare .car-image img').data('postid');
  var $third_post = $('#third-car-compare .car-image img').data('postid');
  var $link_page = $('#compare-btn-wrapper a').data('gopage');
  if ($first_post != undefined && $second_post != undefined && $third_post != undefined){
    $('#compare-btn-wrapper a').attr('href', $link_page+'?compare-id='+$first_post+','+$second_post+','+$third_post);
  }else if ($first_post != undefined && $second_post != undefined) {
    $('#compare-btn-wrapper a').attr('href', $link_page+'?compare-id='+$first_post+','+$second_post);
  }else {
    //alert('nothing to compare');
    $('#compare-btn-wrapper a').attr('href', 'javascript:void(0);');
  }
}

function article_page(){
  $(document).on('click', '.post-archive-category .month-item', function(event){
    event.preventDefault();
    var $month = $(this).data('monthview');
    console.log($month);
  });
}